const main = resolve => require(['@/views/index.vue'], resolve);
const home = resolve => require(['@/views/home.vue'], resolve);
const client = resolve => require(['@/views/client.vue'], resolve);
const about = resolve => require(['@/views/about.vue'], resolve);
const productServe = resolve => require(['@/views/productServe.vue'], resolve);
const drivingServe = resolve => require(['@/views/drivingServe.vue'], resolve);
const educationServe = resolve => require(['@/views/educationServe.vue'], resolve);
const drivingSupervise = resolve => require(['@/views/drivingSupervise.vue'], resolve);
const logisticsServe = resolve => require(['@/views/logisticsServe.vue'], resolve);
const servicecar = resolve => require(['@/views/servicecar.vue'], resolve);
const egovernment = resolve => require(['@/views/egovernment.vue'], resolve);
const citymanagement = resolve => require(['@/views/citymanagement.vue'], resolve);
const siHaoLu = resolve => require(['@/views/sihaolu.vue'], resolve);
const safetyManagement = resolve => require(['@/views/safetyManagement.vue'], resolve);
const onlineCarHailing = resolve => require(['@/views/onlineCarHailing.vue'], resolve);
const carMonitoring = resolve => require(['@/views/carMonitoring.vue'], resolve);
const trafficMonitorCommand = resolve => require(['@/views/trafficMonitorCommand.vue'], resolve);
const VehicleTechnicalFile = resolve => require(['@/views/VehicleTechnicalFile.vue'], resolve);
const drivingTime = resolve => require(['@/views/drivingTime.vue'], resolve);
const xianDrivingTime = resolve => require(['@/views/xianDrivingTime.vue'], resolve);
const electronicLicense = resolve => require(['@/views/electronicLicense.vue'], resolve);
/* ----  */
const transportationIntegration = resolve => require(['@/views/transportationIntegration.vue'], resolve);
const onlineFreight = resolve => require(['@/views/onlineFreight.vue'], resolve);
const networking = resolve => require(['@/views/networking.vue'], resolve);
const castorMOM = resolve => require(['@/views/castorMOM.vue'], resolve);
const castorMES = resolve => require(['@/views/castorMES.vue'], resolve);
const castorEMS = resolve => require(['@/views/castorEMS.vue'], resolve);
const castorEAM = resolve => require(['@/views/castorEAM.vue'], resolve);
const castorMI = resolve => require(['@/views/castorMI.vue'], resolve);
const castorLIMS = resolve => require(['@/views/castorLIMS.vue'], resolve);
const castorSPC  = resolve => require(['@/views/castorSPC.vue'], resolve);
const castorIOT = resolve => require(['@/views/castorIOT.vue'], resolve);
const castorTMS  = resolve => require(['@/views/castorTMS.vue'], resolve);

const routerPC = [
  {
    path: "/",
    // 重定向，默认首页
    redirect: "/home",
    meta: {
      title: "首页",
    },
  },
  {
    path: "/home",
    component: main,
    children: [
      {
        path: "",
        name: "home",
        component: home,
        meta: { title: "首页" },
      }
    ]
  },
  {
    path: "/client",
    component: main,
    children: [
      {
        path: "",
        name: "client",
        component: client,
        meta: { title: "客户案例" },
      }
    ]
  },
  {
    path: "/about",
    component: main,
    children: [
      {
        path: "",
        name: "about",
        component: about,
        meta: { title: "关于我们" },
      }
    ]
  },
  {
    path: "/productServe",
    component: main,
    children: [
      {
        path: "",
        name: "productServe",
        component: productServe,
      }
    ]
  },
  {
    path: "/drivingServe",
    component: main,
    children: [
      {
        path: "",
        name: "drivingServe",
        component: drivingServe,
      }
    ]
  },
  {
    path: "/educationServe",
    component: main,
    children: [
      {
        path: "",
        name: "educationServe",
        component: educationServe,
      }
    ]
  },
  {
    path: "/drivingSupervise",
    component: main,
    children: [
      {
        path: "",
        name: "drivingSupervise",
        component: drivingSupervise,
      }
    ]
  },
  {
    path: "/logisticsServe",
    component: main,
    children: [
      {
        path: "",
        name: "logisticsServe",
        component: logisticsServe,
      }
    ]
  },
  {
    path: "/servicecar",
    component: main,
    children: [
      {
        path: "",
        name: "servicecar",
        component: servicecar,
      }
    ]
  },
  {
    path: "/egovernment",
    component: main,
    children: [
      {
        path: "",
        name: "egovernment",
        component: egovernment,
      }
    ]
  },
  {
    path: "/citymanagement",
    component: main,
    children: [
      {
        path: "",
        name: "citymanagement",
        component: citymanagement
      }
    ]
  },
  {
    path: "/siHaoLu",
    component: main,
    children: [
      {
        path: "",
        name: "siHaoLu",
        component: siHaoLu
      }
    ]
  },
  {
    path: "/safetyManagement",
    component: main,
    children: [
      {
        path: "",
        name: "safetyManagement",
        component: safetyManagement
      }
    ]
  },
  {
    path: "/onlineCarHailing",
    component: main,
    children: [
      {
        path: "",
        name: "onlineCarHailing",
        component: onlineCarHailing
      }
    ]
  },
  {
    path: "/carMonitoring",
    component: main,
    children: [
      {
        path: "",
        name: "carMonitoring",
        component: carMonitoring
      }
    ]
  },
  {
    path: "/trafficMonitorCommand",
    component: main,
    children: [
      {
        path: "",
        name: "trafficMonitorCommand",
        component: trafficMonitorCommand
      }
    ]
  },
  {
    path: "/VehicleTechnicalFile",
    component: main,
    children: [
      {
        path: "",
        name: "VehicleTechnicalFile",
        component: VehicleTechnicalFile
      }
    ]
  },
  {
    path: "/drivingTime",
    component: main,
    children: [
      {
        path: "",
        name: "drivingTime",
        component: drivingTime
      }
    ]
  },
  {
    path: "/xianDrivingTime",
    component: main,
    children: [
      {
        path: "",
        name: "xianDrivingTime",
        component: xianDrivingTime
      }
    ]
  },
  {
    path: "/electronicLicense",
    component: main,
    children: [
      {
        path: "",
        name: "electronicLicense",
        component: electronicLicense,
      }
    ]
  },
  {
    path: "/transportationIntegration",
    component: main,
    children: [
      {
        path: "",
        name: "transportationIntegration",
        component: transportationIntegration
      }
    ]
  },
  {
    path: "/onlineFreight",
    component: main,
    children: [
      {
        path: "",
        name: "onlineFreight",
        component: onlineFreight
      }
    ]
  },
  {
    path: "/networking",
    component: main,
    children: [
      {
        path: "",
        name: "networking",
        component: networking
      }
    ]
  },
  {
    path: "/castorMOM",
    component: main,
    children: [
      {
        path: "",
        name: "castorMOM",
        component: castorMOM
      }
    ]
  },
  {
    path: "/castorMES",
    component: main,
    children: [
      {
        path: "",
        name: "castorMES",
        component: castorMES
      }
    ]
  },
  {
    path: "/castorEMS",
    component: main,
    children: [
      {
        path: "",
        name: "castorEMS",
        component: castorEMS
      }
    ]
  },
  {
    path: "/castorEAM",
    component: main,
    children: [
      {
        path: "",
        name: "castorEAM",
        component: castorEAM
      }
    ]
  },
  {
    path: "/castorMI",
    component: main,
    children: [
      {
        path: "",
        name: "castorMI",
        component: castorMI
      }
    ]
  },
  {
    path: "/castorLIMS",
    component: main,
    children: [
      {
        path: "",
        name: "castorLIMS",
        component: castorLIMS
      }
    ]
  },
  {
    path: "/castorSPC",
    component: main,
    children: [
      {
        path: "",
        name: "castorSPC",
        component: castorSPC
      }
    ]
  },
  {
    path: "/castorIOT",
    component: main,
    children: [
      {
        path: "",
        name: "castorIOT",
        component: castorIOT
      }
    ]
  },
  {
    path: "/castorTMS",
    component: main,
    children: [
      {
        path: "",
        name: "castorTMS",
        component: castorTMS
      }
    ]
  },
]

export default routerPC;
