import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './util/rem';
import VueAMap from 'vue-amap';
import 'animate.css';
import VueLazyload from "vue-lazyload";

Vue.prototype.$eventBus = new Vue();
// Vue.use(animated);
Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI, { size: 'mini', zIndex: 3000 });
Vue.use(VueAMap);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: require('../src/assets/image/loading.gif'),
  attempt: 1
});

VueAMap.initAMapApiLoader({
  key: '15fba5c84d702d936e1c8690eb58f95d',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
