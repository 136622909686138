import Vue from "vue";
import Router from "vue-router";
import routerM from "./routerM.js";
import routerPC from "./routerPC.js";

// 通过vue.use(插件),安装插件
Vue.use(Router);

const ISMOBILE = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
};

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 创建VueRouter对象
const router = new Router({
  // 配置路由和组件之间的映射关系
  routes: ISMOBILE() ? routerM : routerPC,
  // 路径拼接，hash，history，abstract
  mode: "hash",
  // 修改所有linkActiveClass的名字为active
  linkActiveClass: "active",
  scrollBehavior(to) {
    // return 期望滚动到哪个的位置
    if (!to.query.id) {
      return {
        top: 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (!to.query.id) {
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
  }
  next();
});

export default router;
