const main = resolve => require(['@/views/mViews/mindex.vue'], resolve);
const home = resolve => require(['@/views/mViews/mhome.vue'], resolve);
const client = resolve => require(['@/views/mViews/mclient.vue'], resolve);
const about = resolve => require(['@/views/mViews/mabout.vue'], resolve);
const productServe = resolve => require(['@/views/mViews/mproductServe.vue'], resolve);
const egovernment = resolve => require(['@/views/mViews/megovernment.vue'], resolve);
const sihaolu = resolve => require(['@/views/mViews/msihaolu.vue'], resolve);
const trafficMonitorCommand = resolve => require(['@/views/mViews/mtrafficMonitorCommand.vue'], resolve);
const onlinecarhailing = resolve => require(['@/views/mViews/monlineCarHailing.vue'], resolve);
const citymanagement = resolve => require(['@/views/mViews/mcitymanagement.vue'], resolve);
const drivingSupervise = resolve => require(['@/views/mViews/mdrivingSupervise.vue'], resolve);
const carmonitoring = resolve => require(['@/views/mViews/mcarmonitoring.vue'], resolve);
const safetymanagement = resolve => require(['@/views/mViews/msafetyManagement.vue'], resolve);
const VehicleTechnicalFile = resolve => require(['@/views/mViews/mVehicleTechnicalFile.vue'], resolve);
const logisticsServe = resolve => require(['@/views/mViews/mlogisticsServe.vue'], resolve);
const servicecar = resolve => require(['@/views/mViews/mservicecar.vue'], resolve);
const drivingTime = resolve => require(['@/views/mViews/mdrivingTime.vue'], resolve);
const educationServe = resolve => require(['@/views/mViews/meducationServe.vue'], resolve);
const xianDrivingTime = resolve => require(['@/views/mViews/mxianDrivingTime.vue'], resolve);
const mnavmenu = resolve => require(['@/views/mViews/mnavmenu.vue'], resolve);
const electronicLicense = resolve => require(['@/views/mViews/melectronicLicense.vue'], resolve);
/* ---- */
const mtransportationIntegration = resolve => require(['@/views/mViews/mtransportationIntegration.vue'], resolve);
const monlineFreight = resolve => require(['@/views/mViews/monlineFreight.vue'], resolve);
const mnetworking = resolve => require(['@/views/mViews/mnetworking.vue'], resolve);
const mcastorMOM = resolve => require(['@/views/mViews/mcastorMOM.vue'], resolve);
const mcastorMES = resolve => require(['@/views/mViews/mcastorMES.vue'], resolve);
const mcastorEMS = resolve => require(['@/views/mViews/mcastorEMS.vue'], resolve);
const mcastorEAM = resolve => require(['@/views/mViews/mcastorEAM.vue'], resolve);
const mcastorMI = resolve => require(['@/views/mViews/mcastorMI.vue'], resolve);
const mcastorLIMS = resolve => require(['@/views/mViews/mcastorLIMS.vue'], resolve);
const mcastorSPC  = resolve => require(['@/views/mViews/mcastorSPC.vue'], resolve);
const mcastorIOT = resolve => require(['@/views/mViews/mcastorIOT.vue'], resolve);
const mcastorTMS  = resolve => require(['@/views/mViews/mcastorTMS.vue'], resolve);


const routerM = [ 
  {
    path: "/",
    // 重定向，默认首页
    redirect: "/mhome",
    meta: {
      title: "首页",
    },
  },
  {
    path: "/mhome",
    component: main,
    children: [
      {
        path: "",
        name: "mhome",
        component: home,
        meta: { title: "首页" },
      },
    ]
  },
  {
    path: "/mclient",
    component: main,
    children: [
      {
        path: "",
        name: "mclient",
        component: client,
        meta: { title: "客户案例" }
      },
    ]
  },
  {
    path: "/mabout",
    component: main,
    children: [
      {
        path: "",
        name: "mabout",
        component: about,
        meta: { title: "关于我们" }
      }
    ]
  },
  {
    path: "/mproductServe",
    component: main,
    children: [
      {
        path: "",
        name: "mproductServe",
        component: productServe,
        meta: { title: "产品与解决方案" }
      }
    ] 
  },
  {
    path: "/megovernment",
    component: main,
    children: [
      {
        path: "",
        name: "megovernment",
        component: egovernment,
        meta: { title: "电子运政平台" }
      }
    ] 
  },
  {
    path: "/msihaolu",
    component: main,
    children: [
      {
        path: "",
        name: "msihaolu",
        component: sihaolu,
        meta: { title: "四好农村路管理平台" }
      }
    ] 
  },
  {
    path: "/mtrafficMonitorCommand",
    component: main,
    children: [
      {
        path: "",
        name: "mtrafficMonitorCommand",
        component: trafficMonitorCommand,
        meta: { title: "交通运行监测与指挥平台" }
      }
    ] 
  },
  {
    path: "/monlinecarhailing",
    component: main,
    children: [
      {
        path: "",
        name: "monlinecarhailing",
        component: onlinecarhailing,
        meta: { title: "网约车监管平台" }
      }
    ] 
  },
  {
    path: "/mcitymanagement",
    component: main,
    children: [
      {
        path: "",
        name: "mcitymanagement",
        component: citymanagement,
        meta: { title: "绿色城管监管平台" }
      }
    ] 
  },
  {
    path: "/mdrivingSupervise",
    component: main,
    children: [
      {
        path: "",
        name: "mdrivingSupervise",
        component: drivingSupervise,
        meta: { title: "驾培监管服务平台" }
      }
    ] 
  },
  {
    path: "/mcarmonitoring",
    component: main,
    children: [
      {
        path: "",
        name: "mcarmonitoring",
        component: carmonitoring,
        meta: { title: "单北斗车辆智能服务云平台" }
      }
    ] 
  },
  {
    path: "/msafetymanagement",
    component: main,
    children: [
      {
        path: "",
        name: "msafetymanagement",
        component: safetymanagement,
        meta: { title: "安全管理平台" }
      }
    ] 
  },
  {
    path: "/mVehicleTechnicalFile",
    component: main,
    children: [
      {
        path: "",
        name: "mVehicleTechnicalFile",
        component: VehicleTechnicalFile,
        meta: { title: "车辆技术档案管理系统" }
      }
    ] 
  },
  {
    path: "/mlogisticsServe",
    component: main,
    children: [
      {
        path: "",
        name: "mlogisticsServe",
        component: logisticsServe,
        meta: { title: "物流saas管理平台" }
      }
    ] 
  },
  {
    path: "/mservicecar",
    component: main,
    children: [
      {
        path: "",
        name: "mservicecar",
        component: servicecar,
        meta: { title: "公务车管理平台" }
      }
    ] 
  },
  {
    path: "/mdrivingTime",
    component: main,
    children: [
      {
        path: "",
        name: "mdrivingTime",
        component: drivingTime,
        meta: { title: "驾培计时平台" }
      }
    ] 
  },
  {
    path: "/meducationServe",
    component: main,
    children: [
      {
        path: "",
        name: "meducationServe",
        component: educationServe,
        meta: { title: "远程教育系统" }
      }
    ] 
  },
  {
    path: "/mxianDrivingTime",
    component: main,
    children: [
      {
        path: "",
        name: "mxianDrivingTime",
        component: xianDrivingTime,
        meta: { title: "智慧云驾培" }
      }
    ] 
  },
  {
    path: "/mnavmenu",
    component: main,
    children: [
      {
        path: "",
        name: "mnavmenu",
        component: mnavmenu,
        meta: { title: "产品与解决方案" }
      }
    ] 
  },
  {
    path: "/melectronicLicense",
    component: main,
    children: [
      {
        path: "",
        name: "melectronicLicense",
        component: electronicLicense,
        meta: { title: "道路运输电子证照系统" }
      }
    ] 
  },
  {
    path: "/mtransportationIntegration",
    component: main,
    children: [
      {
        path: "",
        name: "mtransportationIntegration",
        component: mtransportationIntegration,
        meta: { title: "城乡交通一体化管理云平台" }
      }
    ] 
  },
  {
    path: "/monlineFreight",
    component: main,
    children: [
      {
        path: "",
        name: "monlineFreight",
        component: monlineFreight,
        meta: { title: "网络货运信息监测系统" }
      }
    ] 
  },
  {
    path: "/mnetworking",
    component: main,
    children: [
      {
        path: "",
        name: "mnetworking",
        component: mnetworking,
        meta: { title: "重点营运车辆联网联控平台" }
      }
    ] 
  },
  {
    path: "/mcastorMOM",
    component: main,
    children: [
      {
        path: "",
        name: "mcastorMOM",
        component: mcastorMOM,
        meta: { title: "Castor MOM产品体系" }
      }
    ] 
  },
  {
    path: "/mcastorMES",
    component: main,
    children: [
      {
        path: "",
        name: "mcastorMES",
        component: mcastorMES,
        meta: { title: "Castor MES" }
      }
    ] 
  },
  {
    path: "/mcastorEMS",
    component: main,
    children: [
      {
        path: "",
        name: "mcastorEMS",
        component: mcastorEMS,
        meta: { title: "Castor EMS" }
      }
    ] 
  },
  {
    path: "/mcastorEAM",
    component: main,
    children: [
      {
        path: "",
        name: "mcastorEAM",
        component: mcastorEAM,
        meta: { title: "Castor EAM" }
      }
    ] 
  },
  {
    path: "/mcastorMI",
    component: main,
    children: [
      {
        path: "",
        name: "mcastorMI",
        component: mcastorMI,
        meta: { title: "Castor MI" }
      }
    ] 
  },
  {
    path: "/mcastorLIMS",
    component: main,
    children: [
      {
        path: "",
        name: "mcastorLIMS",
        component: mcastorLIMS,
        meta: { title: "Castor LIMS" }
      }
    ] 
  },
  {
    path: "/mcastorSPC",
    component: main,
    children: [
      {
        path: "",
        name: "mcastorSPC",
        component: mcastorSPC,
        meta: { title: "Castor SPC" }
      }
    ] 
  },
  {
    path: "/mcastorIOT",
    component: main,
    children: [
      {
        path: "",
        name: "mcastorIOT",
        component: mcastorIOT,
        meta: { title: "Castor LOT" }
      }
    ] 
  },
  {
    path: "/mcastorTMS",
    component: main,
    children: [
      {
        path: "",
        name: "mcastorTMS",
        component: mcastorTMS,
        meta: { title: "Castor TMS" }
      }
    ] 
  }
];
export default routerM;
