<template>
  <transition name="fade" mode="out-in">
    <router-view></router-view>
  </transition>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="less">
@import 'assets/styles/scrollbar.scss';
/*
*公用样式
*/
html,
body {
  // min-width: 1300px;
  margin: 0;
  padding: 0;
  width: 100%;
}

p {
  margin: 0;
  padding: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
